import { useEffect } from "react";
import { ToggleButton, togglePlan } from "@components/atoms/ToggleButton";
import {
  viewport,
  colors,
  font,
  lineHeight,
} from "../../../styles/cssinjs/helpers/variables.js";

import ContactCard from "./ContactCard";

export default function PricingPlan({
  plans,
  toggle,
  planInfo,
  className,
  contactCard,
}) {
  useEffect(() => {
    togglePlan();
  }, []);
  const Card = ({ data, index }) => {
    let cardContainerClassName;
    const appMode = process.env.APP_MODE;
    const link = data?.button?.href?.[appMode];
    if (index == 0) {
      cardContainerClassName = "md:ml-3 md:mr-2.5 md:mt-5";
    } else if (index == 1) {
      cardContainerClassName = "md:ml-3 md:mr-2.5 md:mt-3 border-brand";
    } else if (index == 2) {
      cardContainerClassName = "md:ml-3 md:mr-2.5 md:mt-3";
    }
    const Costs = ({ data }) =>
      data.map((cost, index) => <span key={index}>{cost}</span>);

    const Header = () => {
      const hasMultiplePrices = Array.isArray(data.price);
      return (
        <>
          <div className="fx fx--cv fx--sb">
            <h2 className="highlight">{data.heading}</h2>
            {data.hasOwnProperty("headingIcon") && (
              <figure>
                <svg
                  width={data.headingIconDimension[0]}
                  height={data.headingIconDimension[1]}
                >
                  <use href={`/sprite.svg#${data.headingIcon}`}></use>
                </svg>
              </figure>
            )}
          </div>
          <p className="plan-user">{data.label}</p>
          {data.hasOwnProperty("discount") && (
            <figure className="abs--img discount-img abs--img-card">
              <svg
                width={data.discountDimension[0]}
                height={data.discountDimension[1]}
              >
                <use href={`/sprite.svg#${data.discount}`}></use>
              </svg>
            </figure>
          )}
          {data.hasOwnProperty("discountMonthly") && (
            <figure
              className="abs--img monthly-billing-span md:d-none abs--img-card"
              dangerouslySetInnerHTML={{ __html: data.discountMonthly }}
            />
          )}
          <p className="plan-price">
            <strong
              className={`highlight  ${hasMultiplePrices && "price-wrapper"}`}
            >
              ${hasMultiplePrices ? <Costs data={data.price} /> : data.price}
            </strong>{" "}
            <span className="plan-duration-wrap">{data.duration}</span>
          </p>
          <style jsx>{`
            .fx--sb > figure {
              opacity: 1;
              visibility: visible;
            }
            figure {
              opacity: 0;
              visibility: hidden;
            }
            figure.active {
              opacity: 1;
              visibility: visible;
            }
            .abs--img {
              top: 100px;
              left: 96px;
              position: absolute;
              transition-property: opacity, visibility;
              transition-duration: 0.5s;
            }
            #dis-img-toggle {
              position: relative;
              left: -30%;
              top: -5px;
              display: inline-block;
              opacity: 0;
            }
            #annual-dis-img-cta.abs--img,
            #annual-dis-img.abs--img,
            #annual-pro-dis-img.abs--img,
            #dis-img-toggle.abs--img,
            #monthly-dis-img-cta.abs--img,
            #monthly-dis-img.abs--img {
              opacity: 0;
            }
            #annual-dis-img-cta.active,
            #annual-dis-img.active,
            #annual-pro-dis-img.active,
            #dis-img-toggle.active,
            #monthly-dis-img-cta.active,
            #monthly-dis-img.active {
              opacity: 1;
            }
            .billing-toggle {
              margin-left: 38%;
              margin-right: -25px;
            }
            @media (max-width: ${viewport.tablet.sm}) {
              #dis-img-toggle {
                left: 20%;
              }
              .billing-toggle #dis-img-toggle {
                left: -30%;
                top: -5px;
              }
            }
          `}</style>
        </>
      );
    };
    const Features = () => {
      const appMode = process.env.APP_MODE;
      const link = data?.learnMore?.href?.[appMode] || data?.learnMore?.href;
      return (
        <>
          <div className="mt-2 lg:mb-4 md:mb-2 plan-benefits">
            <ul
              className="ticked-bulletin mb-0 mt-0"
              aria-label={`features in ${data.heading}`}
            >
              {data.features.map((feature, index) => (
                <li key={index} dangerouslySetInnerHTML={{ __html: feature }} />
              ))}
            </ul>
          </div>
          <a
            className={`arrow-link text-brandcolor ml-1 ${
              data.features.length == 1 ? "lg:mt-5 inline-block" : ""
            } `}
            id={data.learnMore?.id}
            href={link}
          >
            {data.learnMore?.content && (
              <svg width="20" height="20" className="arrow-right">
                <use href={`/sprite.svg#small-arrow`}></use>
              </svg>
            )}

            <span>{data.learnMore?.content}</span>
          </a>
          <style jsx>
            {`
              .arrow-right {
                margin-right: 8px;
                vertical-align: middle;
              }
              .arrow-link {
                position: absolute;
                left: 38px;
                bottom: 24px;
              }
              .arrow-link span {
                vertical-align: middle;
              }
              .ticked-bulletin li {
                margin-bottom: 0px !important;
              }
              .ticked-bulletin li:last-child::before {
                ${index > 0
                  ? `
              background: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23fff' d='M0 0h20v20H0z'/%3E%3Cpath d='M16 15H4l6-6 6 6zM16 9H4l6-6 6 6z' stroke='%23556270' stroke-width='1.1' stroke-linejoin='round'/%3E%3C/svg%3E")
                no-repeat;
              top: 5px;
              `
                  : ``}
              }
            `}
          </style>
        </>
      );
    };
    const ToggleBtn = () => (
      <div className="billing-type-wrap billing-toggle-mobile-view lg:d-none mt-2">
        <div className="md:ml-0 togg-btn-wrap">
          <ToggleButton
            label={`click to toggle between ${toggle?.billingType?.[0]} and ${toggle?.billingType?.[1]}.`}
            announcement={{
              onState:
                "annual billing - free plan $0 per month, pro plan $5 per month, team $5 per month.",
              offState:
                "monthly billing - free plan $0 per month, pro plan $12 per month, team $9 per month.",
            }}
            toggled={true}
          />
        </div>
        <span className="monthly-billing-span">
          {data.offer && data.offer.length > 1 && (
            <>
              {data.offer}
              <br />
            </>
          )}
          <span className="md:d-block w-auto">{data.billingType}</span>
        </span>
        <span className="fw-700 annual-billing-span">{data.billingType}</span>
        <style jsx>{`
          .togg-btn-wrap {
            display: inline-block;
            vertical-align: middle;
            margin: 0 16px 0 16px;
          }
          @media (max-width: ${viewport.tablet.sm}) {
            .billing-toggle-mobile-view .annual-billing-span {
              display: none;
            }
            .billing-toggle-mobile-view .annual-billing-span.active,
            .billing-toggle-mobile-view .monthly-billing-span.active {
              display: inline-block;
              width: auto;
              vertical-align: middle;
            }
            .billing-toggle-mobile-view .monthly-billing-span {
              font-size: ${font.size.sm[3]};
              line-height: ${font.size.sm[4]};
              font-weight: ${font.weight.semiBold};
              color: ${colors.primitive.green[200]};
              display: none;
            }
            .billing-toggle-mobile-view .monthly-billing-span span {
              color: ${colors.primitive.black[400]};
            }
          }
        `}</style>
      </div>
    );

    const CtaButton = () => (
      <div className="plan-cta">
        <a
          href={link}
          className={`${data.button.variant} w-100`}
          id={data.button.id}
        >
          {data.button.content}
        </a>
        {data.hasOwnProperty("notice") && (
          <div className="notice fx">
            <span> {data.notice} </span>
            <svg width="21" height="20">
              <use href="/sprite.svg#smiley-emoji"></use>
            </svg>
          </div>
        )}
        <style jsx>{`
          .notice {
            justify-content: center;
            align-items: center;
            gap: 8px;
            padding: 4px 16px;
            border-radius: 14px;
            background-color: ${colors.primitive.green[600]};
            margin-top: 16px;
          }
          .notice > span {
            max-width: 177px;
            font-size: ${font.size.sm[1]};
            font-weight: bold;
            line-height: ${lineHeight[4]};
            text-transform: uppercase;
            letter-spacing: 0.25px;
            color: ${colors.primitive.green[700]};
          }
        `}</style>
      </div>
    );

    return (
      <li className={cardContainerClassName}>
        <Header />
        {data.hasOwnProperty("hasToggle") && <ToggleBtn />}
        <CtaButton />
        <Features />
        <style jsx>{`
          .border-brand {
            border: 1.5px solid ${colors.primitive.green.light};
          }
          @media (max-width: ${viewport.tablet.sm}) {
            .border-brand {
              border: none;
            }
          }
        `}</style>
      </li>
    );
  };

  const Toggle = () => (
    <div className="billing-type-wrap container fx lg:pb-3">
      <div className="billing-toggle md:d-none">
        <span
          className="w-auto monthly-billing-span"
          id="monthly-billing-span"
          aria-hidden="true"
        >
          {toggle.billingType[0]}
        </span>
        <div className="togg-btn-wrap">
          <ToggleButton
            label={`click to toggle between ${toggle.billingType[0]} and ${toggle.billingType[1]}.`}
            announcement={{
              onState:
                "annual billing - free plan $0 per month, pro plan $5 per month, team $5 per month.",
              offState:
                "monthly billing - free plan $0 per month, pro plan $12 per month, team $9 per month.",
            }}
            toggled={true}
          />
        </div>

        <span
          className="w-auto annual-billing-span"
          id="annual-billing-span"
          aria-hidden="true"
        >
          {toggle.billingType[1]}
        </span>
        <figure
          className="abs--img monthly-billing-span w-auto"
          id="dis-img-toggle"
        >
          <svg width="106" height="24">
            <use href="/sprite.svg#fifty-annual"></use>
          </svg>
        </figure>
      </div>
      <style jsx>{`
        figure {
          opacity: 0;
        }
        figure.active {
          opacity: 1;
        }
        .togg-btn-wrap {
          display: inline-block;
          vertical-align: middle;
          margin: 0 16px 0 16px;
        }
        .billing-type-wrap .annual-billing-span,
        .billing-type-wrap .monthly-billing-span {
          vertical-align: middle;
          display: inline-block;
          text-align: left;
          width: calc(50% - 50px);
          font-size: ${font.size.sm[4]};
          color: ${colors.primitive.grey[300]};
        }
        .annual-billing-span.active,
        .monthly-billing-span.active {
          font-weight: ${font.weight.bold};
          color: ${colors.primitive.black[100]} !important;
        }
        .billing-type-wrap span:first-child {
          text-align: right;
        }
        .abs--img {
          top: 103px;
          left: 91px;
          position: absolute;
          transition-property: opacity;
          transition-duration: 0.5s;
        }
        #dis-img-toggle {
          position: relative;
          left: -28%;
          top: -23px;
          display: inline-block;
          opacity: 0;
        }
        #annual-dis-img-cta.abs--img,
        #annual-dis-img.abs--img,
        #annual-pro-dis-img.abs--img,
        #dis-img-toggle.abs--img,
        #monthly-dis-img-cta.abs--img,
        #monthly-dis-img.abs--img {
          opacity: 0;
        }
        #annual-dis-img-cta.active,
        #annual-dis-img.active,
        #annual-pro-dis-img.active,
        #dis-img-toggle.active,
        #monthly-dis-img-cta.active,
        #monthly-dis-img.active {
          opacity: 1;
        }
        .billing-toggle {
          margin-left: 38%;
          margin-right: -25px;
        }
        @media (max-width: ${viewport.tablet.sm}) {
          .abs--img {
            left: 66px;
          }
          #dis-img-toggle {
            left: 20%;
          }
          .billing-toggle #dis-img-toggle {
            left: -30%;
            top: -5px;
          }
        }
      `}</style>
    </div>
  );
  const PlanInfo = ({ data }) => (
    <div
      className="billing-type-wrap container fx lg:pb-3 md:pl-1 md:text-center lg:d-none"
      id="mobile"
    >
      <div className="billing-savings-off md:pt-2.5 md:pb-7 lg:d-none">
        {data.map((info, index) => (
          <p dangerouslySetInnerHTML={{ __html: info }} key={index} />
        ))}
      </div>
      <style jsx>{`
        .billing-savings-off p {
          font-size: ${font.size.sm[3]};
          line-height: ${lineHeight[6]};
          font-weight: ${font.weight.normal};
          letter-spacing: normal;
          text-align: left;
          color: ${colors.primitive.black[400]};
        }
        .billing-savings-off p a {
          text-decoration: underline;
        }
      `}</style>
    </div>
  );
  return (
    <>
      {toggle && <Toggle />}
      <ul
        className={`container card--default pr-card pr-card-toggle has-three-col has-gutter-md mx-auto lg:mb-1 ${className}`}
        aria-label="Pricing plan comparison."
      >
        {plans.map((plan, index) => (
          <Card data={plan} key={index} index={index} />
        ))}
      </ul>
      {contactCard && (
        <ContactCard
          data={contactCard}
          className="bg-dull-grey lg:mb-4 lg:mt-10 mt-3"
        />
      )}
      {planInfo && <PlanInfo data={planInfo} />}
    </>
  );
}
